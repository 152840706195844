var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"max-width-1400 full-width ma-auto",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","py-5":"","text-xs-center":"","px-3":""}},[_c('p',{staticClass:"graphik-medium mb-5 dark-grey--text",class:{'font-25': _vm.$vuetify.breakpoint.smAndUp, 'font-18': _vm.$vuetify.breakpoint.xsOnly}},[_vm._v(_vm._s(_vm.$ml.get('content_coverages_cars_title')))]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pa-0 pb-5"},[_c('v-layout',{attrs:{"row":"","wrap":"","align-space-around":"","fill-height":_vm.$vuetify.breakpoint.mdAndUp}},_vm._l((_vm.coverages),function(coverage,index){return _c('v-flex',{key:index,attrs:{"xs12":"","md4":"","d-flex":"","pb-5":_vm.$vuetify.breakpoint.smAndDown && (index + 1) !== _vm.coverages.length}},[_c('v-card',{staticClass:"border-radius-10 mx-3 elevation-5"},[_c('v-card-title',{staticClass:"justify-center align-center",class:{
                  'primary': index === 0,
                  'background': index !== 0
                }},[_c('p',{staticClass:"graphik-bold mb-0",class:{
                    'font-25': _vm.$vuetify.breakpoint.smAndUp,
                    'font-20': _vm.$vuetify.breakpoint.xsOnly,
                    'white--text': index === 0,
                    'primary--text': index === 1,
                    'primary--text': index === 2
                  }},[_vm._v(_vm._s(coverage.title))])]),_c('v-card-text',{staticClass:"d-flex pa-0"},[_c('div',{staticClass:"pa-5 align-self-center text-xs-center"},[_vm._l((coverage.coverages),function(cover,idx){return _c('p',{key:idx + 'c' + index,staticClass:"graphik-light font-16"},[_vm._v("\n                    "+_vm._s(cover)+"\n                  ")])}),(index===0)?_c('p',{staticClass:"font-20 my-0 pt-3 graphik-bold pantene--text"},[_vm._v(_vm._s(_vm.$ml.get('general_recommended')))]):_vm._e()],2)])],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }