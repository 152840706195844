<template>
  <v-layout row wrap class="max-width-1400 full-width ma-auto">
    <v-flex xs12 py-5 text-xs-center px-3>
      <p class="graphik-medium mb-5 dark-grey--text" :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-18': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_coverages_cars_title') }}</p>
      
      <v-card flat>
        <v-card-title class="pa-0 pb-5">
          <v-layout row wrap align-space-around :fill-height="$vuetify.breakpoint.mdAndUp">
            <v-flex xs12 md4 d-flex v-for="(coverage, index) in coverages" :key="index" :pb-5="$vuetify.breakpoint.smAndDown && (index + 1) !== coverages.length">
              <v-card class="border-radius-10 mx-3 elevation-5">
                <v-card-title
                  class="justify-center align-center"
                  :class="{
                    'primary': index === 0,
                    'background': index !== 0
                  }"
                >
                  <p
                    class="graphik-bold mb-0"
                    :class="{
                      'font-25': $vuetify.breakpoint.smAndUp,
                      'font-20': $vuetify.breakpoint.xsOnly,
                      'white--text': index === 0,
                      'primary--text': index === 1,
                      'primary--text': index === 2
                    }"
                  >{{ coverage.title }}</p>
                </v-card-title>

                <v-card-text class="d-flex pa-0">
                  <div class="pa-5 align-self-center text-xs-center">
                    <p
                      class="graphik-light font-16"
                      v-for="(cover, idx) in coverage.coverages" :key="idx + 'c' + index"
                    >
                      {{ cover }}
                    </p>

                    <p v-if="index===0" class="font-20 my-0 pt-3 graphik-bold pantene--text">{{ $ml.get('general_recommended') }}</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentCoveragesCars',
  data () {
    return {
      tab: null,
      coverages: [
        {
          title: this.$ml.get('fields_amplia'),
          coverages: [
            this.$ml.get('coverages_materials'),
            this.$ml.get('coverages_steal'),
            this.$ml.get('coverages_rc_usa'),
            this.$ml.get('coverages_medical'),
            this.$ml.get('coverages_assistance'),
            this.$ml.get('coverages_others')
          ]
        },
        {
          title: this.$ml.get('fields_limitada'),
          coverages: [
            this.$ml.get('coverages_steal'),
            this.$ml.get('coverages_rc_mexico'),
            this.$ml.get('coverages_medical'),
            this.$ml.get('coverages_assistance')
          ]
        },
        {
          title: this.$ml.get('fields_rc'),
          coverages: [
            this.$ml.get('coverages_rc_mexico'),
            this.$ml.get('coverages_medical'),
            this.$ml.get('coverages_assistance')
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
  .border-radius-10 {
    border-radius: 10px !important;
  }

  .background {
    background-color: #F1EEFB;
  }
</style>

<style>
  .height-60-percent {
    max-height: 60% !important;
    min-height: 60% !important;
    height: 60% !important;
  }
</style>